import { getAuthToken, isAuthenticated } from "./auth-helper";
import { NotFoundError, BadRequestError } from "./error-classes";

const getRequestUrl = (url, isAuth) => {
  let baseUrl = `${process.env.VUE_APP_URL}`;
  if (!isAuth) {
    baseUrl += "/public";
  }
  const requestUrl = url.startsWith("http") ? url : `${baseUrl}${url}`;
  return requestUrl;
};

const getRequestHeader = async (isAuth, body) => {
  const requestHeaders = {};
  if (body) {
    requestHeaders["Content-Type"] = "application/json";
  }
  if (isAuth) {
    const token = await getAuthToken();
    requestHeaders["Authorization"] = `${token}`;
  }
  return requestHeaders;
};

const sendRequestHelper = async ({
  url,
  body,
  method,
  headers, // additional headers
  requestLabel = "",
  itemName = "",
}) => {
  const isAuth = await isAuthenticated();
  // get the request headers
  const requestHeaders = await getRequestHeader(isAuth, body);

  const requestUrl = getRequestUrl(url, isAuth);

  // send request
  const response = await fetch(requestUrl, {
    method,
    mode: "cors",
    body: JSON.stringify(body),
    headers: {
      ...requestHeaders,
      ...headers,
    },
  });
  // handle response status
  if (!response.ok) {
    if (response.status === 404) {
      throw new NotFoundError(`Not found ${itemName.toLowerCase()}`);
    }
    throw new BadRequestError(
      `An error occurred when ${requestLabel.toLowerCase()}.`
    );
  }
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return await response.json();
  } // may need to handle other content types but not priority now
};

export default sendRequestHelper;
