/**
 * This file is to store all the standard error classes
 */

export class StandardError extends Error {
  constructor(message, name, code) {
    super(message, code);
    this.name = name || "StandardError";
  }
}

export class NotFoundError extends StandardError {
  constructor(message = "Not found") {
    super(message, "NotFoundError", 404);
  }
}

export class BadRequestError extends StandardError {
  constructor(message = "Bad request") {
    super(message, "BadRequestError", 400);
  }
}

export class ServerError extends StandardError {
  constructor(message = "Server error") {
    super(message, "ServerError", 500);
  }
}
