const sideBarModule = {
  state() {
    return {
      display: false,
    };
  },
  mutations: {
    setSidebarDisplay(state, value) {
      state.display = value;
    },
  },
  actions: {
    toggleSidebarDisplay({ state }) {
      state.display = !state.display;
    },
  },
};

export default sideBarModule;
