import Vue from "vue";
import App from "./app/App.vue";
import router from "./app/router";
import store from "./app/store";
// Import plugins
import vuetify from "./app/plugins/vuetify";
import "./app/plugins/vuelidate";
// Amplify
import { Amplify } from "aws-amplify";
import { getDefaultConfig } from "./app/utils/amplify-config";

const setUpApplication = () => {
  const amplifyConfig = getDefaultConfig();
  Amplify.configure({
    ...amplifyConfig,
  });

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};

setUpApplication();
