const alertModule = {
  state() {
    return {
      message: "",
      show: false,
      timeout: 10000,
      type: "",
    };
  },
  mutations: {
    setAlertDisplay(state, isShow) {
      state.show = isShow;
      if (!isShow) {
        state.message = "";
        state.type = "";
      }
    },
    setAlertMessage(state, message) {
      state.message = message;
    },
    setAlertType(state, type) {
      state.type = type;
    },
  },
  actions: {
    callAlert({ commit }, message) {
      commit("setAlertDisplay", true);
      commit("setAlertMessage", message);
    },
    // may used this action to show alert with custom type
    notify({ commit }, { message, type }) {
      commit("setAlertType", type);
      commit("setAlertMessage", message);
      commit("setAlertDisplay", true);
    },
  },
};

export default alertModule;
