import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./route-list";
import { isAuthenticated, isSystemAdmin } from "../utils/auth-helper";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const appMode = process.env.VUE_APP_MODE;
  const signInPath = "/login";
  if (
    to.path !== signInPath &&
    (to.matched.some((record) => record.meta.requireAuth) ||
      appMode === "enterprise")
  ) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const isLoggedIn = await isAuthenticated();

    if (!isLoggedIn) {
      router.app.$store.dispatch("callAlert", "You have to login to access.");
      next({
        path: signInPath,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireSystemAdmin)) {
    // check token if current user is system admin
    const isAdmin = await isSystemAdmin();
    if (!isAdmin) {
      router.app.$store.dispatch("callAlert", "You are not a system admin.");
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
