const routes = [
  // Users
  {
    path: "/users",
    name: "Users",
    component: () => import("../../users/Users.vue"),
    props: true,
    children: [
      {
        path: "create",
        name: "UserCreate",
        component: () => import("../../users/user-create/UserCreate.vue"),
        meta: { requireAuth: true },
      },
      {
        path: "edit",
        name: "UserProfileEdit",
        component: () =>
          import("../../users/user-profile-edit/UserProfileEdit.vue"),
        meta: { requireAuth: true },
      },
      {
        path: ":id",
        component: () => import("../../users/UserDetailPage.vue"),
        props: true,
      },
    ],
  },
  // Questions
  {
    path: "/questions",
    name: "Questions",
    component: () => import("../../questions/Questions.vue"),
    props: true,
    children: [
      {
        path: ":id",
        component: () => import("../../questions/QuestionDetailPage.vue"),
        props: true,
      },
    ],
  },
  // Organizations
  {
    path: "/organizations",
    name: "Organizations",
    component: () => import("../../organizations/Organizations.vue"),
    props: true,
    children: [
      {
        path: "join",
        component: () => import("../../organizations/OrgJoin.vue"),
        props: true,
        meta: { requireAuth: true },
      },
      {
        path: "create",
        name: "CreateOrgs",
        component: () => import("../../organizations/OrgCreate.vue"),
        meta: { requireAuth: true },
      },
      {
        path: "insights/:orgId",
        component: () => import("../../organizations/OrgInsights.vue"),
        props: true,
        meta: { requireAuth: true },
      },
      {
        path: "edit/:orgId",
        component: () => import("../../organizations/OrgEdit"),
        meta: { requireAuth: true },
      },
      {
        path: ":orgId",
        component: () => import("../../organizations/OrgDetail"),
        props: true,
      },
      {
        path: "members/:orgId",
        component: () => import("../../organizations/OrgMembers"),
        props: true,
      },
    ],
  },
  // Auth
  {
    path: "/login",
    name: "SignIn",
    component: () => import("../../auth/SignIn.vue"),
  },
  {
    path: "/logout",
    name: "LogOut",
    component: () => import("../../auth/SignOut.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../../auth/ForgotPassword.vue"),
  },
  {
    path: "/forgot-password-verification",
    name: "ForgotPasswordVerification",
    component: () => import("../../auth/ForgotPasswordVerification.vue"),
  },
  // {
  //   path: "/change-password",
  //   name: "ChangePassword",
  //   component: () => import("../../auth/ChangePassword.vue"),
  // },
  // Contents
  {
    path: "/contents",
    name: "Contents",
    component: () => import("../../contents/Contents.vue"),
    props: true,
    children: [
      {
        path: ":id",
        component: () => import("../../contents/ContentDetailsPage.vue"),
      },
    ],
  },
  {
    path: "/email-prompt",
    name: "EmailPrompt",
    component: () => import("../../email-prompt/EmailPrompt.vue"),
    meta: { requireAuth: true },
  },

  {
    path: "/",
    name: "Homepage",
    component: () => import("../../questions/CuriosityFeed.vue"),
  },
  {
    path: "/my-questions",
    name: "MyQuestions",
    component: () => import("../../questions/MyQuestionPage.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: () => import("../../not-found/NotFound.vue"),
  },
  // System Admin
  {
    path: "/system-admin",
    name: "SystemAdmin",
    component: () => import("../../system-admin/SystemAdmin.vue"),
    meta: { requireSystemAdmin: true },
    children: [
      // Users
      {
        path: "users",
        component: () => import("../../system-admin/users/UserList.vue"),
      },
      {
        path: "users/create",
        component: () =>
          import("../../system-admin/users/user-create/UserCreate.vue"),
      },
      {
        path: "users/edit/:id",
        component: () =>
          import("../../system-admin/users/user-edit/UserEdit.vue"),
      },
      // Organizations
      {
        path: "organizations",
        name: "OrganizationList",
        component: () =>
          import(
            "../../system-admin/organizations/organization-list/OrganizationList.vue"
          ),
      },
      // Contents
      {
        path: "contents",
        name: "ContentList",
        component: () =>
          import("../../system-admin/contents/content-list/ContentList.vue"),
      },
      {
        path: "contents/create",
        name: "CreateContent",
        component: () =>
          import(
            "../../system-admin/contents/content-create/ContentCreate.vue"
          ),
      },
      {
        path: "contents/edit/:id",
        name: "ContentEdit",
        component: () =>
          import("../../system-admin/contents/content-edit/ContentEdit.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/not-found",
  },
];

export default routes;
