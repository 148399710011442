import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);
Vue.use(Vuetify, {
  iconfont: "mdi",
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        inqliGreen: "#9CC9B7",
        inqliPurple: "#A554B6",
        inqliYellow: "#EFB32A",
        inqliRed: "#DD574B",
        inqliDarkBlue: "#334D6E",
        inqliNavy: "#031A4A",
        inqliTableBlack: "#323C47",
        inqliBlack: "#192A3E",
        inqliIconGray: "#C2CFE0",
        inqliGray: "#90A0B7",
        inqliTableGray: "#707683",
        inqliBackground: "#EEEEF6",
      },
      icons: {
        iconFont: "mdi",
      },
    },
  },
});
