/**
 * Use method so later on we can change it to use async/await and send request to server
 * @returns default Amplify Config object
 */
export const getDefaultConfig = () => {
  const mode = process.env.VUE_APP_MODE;
  const config = {
    Auth: {
      region: process.env.VUE_APP_AWS_REGION,
      userPoolId: process.env.VUE_APP_AWS_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
  };
  if (mode === "enterprise") {
    config.Auth.oauth = {
      domain: process.env.VUE_APP_AWS_AUTH_USER_POOL_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.VUE_APP_AWS_AUTH_REDIRECT_URL,
      redirectSignOut: process.env.VUE_APP_AWS_AUTH_REDIRECT_URL,
      responseType: "code",
    };
  }
  return config;
};
