<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>
<script>
import { StandardError } from "./utils/error-classes";
import { getUserInfo } from "./utils/auth-helper";
export default {
  name: "App",
  metaInfo: {
    title: "Inqli",
    titleTemplate: "%s | Inqli",
  },
  async mounted() {
    try {
      await this.$store.dispatch("checkSystemAdmin");
      await this.$store.dispatch("checkUser");
      await this.$store.dispatch("loadUserInfo");
      await this.$store.dispatch("loadUserOrganization");
    } catch (e) {
      if (this.$store.state.user.isSystemAdmin) {
        return;
      }
      const appMode = process.env.VUE_APP_MODE;
      const isLogin = this.$store.state.user.isLogin;

      if (appMode === "enterprise" && isLogin) {
        // check if user is in the enterprise user list
        const userStateInfo = this.$store.state.user.info;
        const userInfo = await getUserInfo();
        const userSub = userInfo.attributes.sub;
        const username = userInfo.username;

        if (userSub !== username && !userStateInfo) {
          this.$router.push("/users/create");
          return;
        }
      }
      this.$store.dispatch(
        "callAlert",
        "An error occurred when loading user info."
      );
    }
  },
  errorCaptured(error) {
    if (typeof error === "string") {
      this.$store.dispatch("callAlert", error);
    } else if (error instanceof StandardError) {
      this.$store.dispatch("callAlert", error.message);
      return false;
    } else {
      console.warn(error);
      return false;
    }
  },
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
body {
  width: 100vw;
}
#app {
  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $inqliNavy;
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  background-color: $inqliBackground;
  font-size: 14px;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
  color: $inqliNavy;
}
h1 {
  font-size: 22px !important;
}
h2 {
  font-size: 18px !important;
}
h3 {
  font-size: 16px !important;
}

h4 {
  font-size: 14px !important;
}

a {
  text-decoration: none !important;
  color: $inqliPurple;
}

li {
  a {
    text-decoration: none !important;
    color: $inqliPurple;
  }
}
.general-link {
  color: $inqliPurple !important; // must use this to override anchor default color
}
a:hover {
  color: $inqliButtonHover !important;
}
a:focus {
  color: $inqliButtonFocus !important;
}
p {
  color: $inqliNavy;
  font-size: 14px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $inqliGray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $inqliGray;
}
.v-toolbar__content {
  width: 100%;
}
.v-input {
  font-size: 14px !important;
}
/** Banner */
.v-application--is-ltr {
  .v-banner__wrapper {
    padding: 6px 8px !important;
    border: none !important;
  }
}
/** Google icon */
.material-icons-round {
  font-size: 13px;
  vertical-align: middle;
}
</style>
