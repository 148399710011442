import Vue from "vue";
import Vuex from "vuex";
import alertModule from "./alert";
import sideBarModule from "./sidebar";
import userModule from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: userModule,
    sideBar: sideBarModule,
    alert: alertModule,
  },
});
