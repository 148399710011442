import { Auth } from "aws-amplify";

/**
 * Get the current user's jwt token.
 * If the session is expired, it will try to refresh the token
 * if the refresh token is expired, it will redirect to login page
 * @returns token a jwt token
 */
export const getAuthToken = async () => {
  try {
    const dataSession = await Auth.currentSession();
    const token = dataSession.getAccessToken().getJwtToken();
    return token;
  } catch (e) {
    // redirect to login page
    window.location.replace("/login");
  }
};

export const getUserInfo = async () => {
  try {
    const userInfo = await Auth.currentUserInfo();
    return userInfo;
  } catch (e) {
    return null;
  }
};

export const getUserOrgDomain = async () => {
  try {
    const userInfo = await Auth.currentUserInfo();
    let email = userInfo.attributes.email;
    if (!email) {
      throw "User email not found";
    }
    const domain = email.split("@").pop();
    return domain;
  } catch (e) {
    return null;
  }
};

export const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const isSystemAdmin = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    return groups.includes("admin");
  } catch (e) {
    return false;
  }
};

export const getUserId = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.username;
  } catch (e) {
    return false;
  }
};
