import { Auth } from "aws-amplify";
import sendRequest from "../utils/send-request-helper";
import { getUserInfo } from "../utils/auth-helper";

const userModule = {
  state: () => ({
    username: "", // user's uuid, get from user sub
    isLogin: false,
    isSystemAdmin: false,
    info: null,
    organizations: [],
  }),
  mutations: {
    setUsername(state, value) {
      state.username = value;
    },
    setLoginState(state, value) {
      state.isLogin = value;
    },
    setUserInfo(state, value) {
      if (state.info == null) {
        state.info = value;
      }
      state.info = { ...state.info, ...value };
    },
    setUserOrganizations(state, value) {
      if (Array.isArray(value)) {
        state.organizations = value;
      }
    },
    setIsSystemAdmin(state, value) {
      state.isSystemAdmin = value;
    },
  },
  actions: {
    // this can throw error
    async login({ commit, dispatch }, { username, password }) {
      await Auth.signIn(username, password);
      const user = await Auth.currentUserInfo();
      commit("setUsername", user.attributes.sub);
      commit("setLoginState", true);
      await dispatch("checkSystemAdmin");
    },
    async logout({ commit }) {
      try {
        await Auth.signOut();
      } catch (e) {
        // does not thing
      }
      commit("setUsername", "");
      commit("setLoginState", false);
      commit("setUserInfo", {});
    },
    /**
     * Check if user is login or not
     */
    async checkUser({ commit }) {
      try {
        const user = await getUserInfo();
        if (!user) {
          throw new Error("User is not login");
        }
        commit("setUsername", user.attributes.sub);
        commit("setLoginState", true);
      } catch (e) {
        commit("setLoginState", false);
      }
    },
    async checkSystemAdmin({ commit }) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        const isAdmin = groups.includes("admin");
        commit("setIsSystemAdmin", isAdmin);
      } catch (e) {
        commit("setIsSystemAdmin", false);
      }
    },
    // this can throw error
    async loadUserInfo({ commit, state }) {
      // get user data if user is login and user info does not available
      if (state.isLogin && state.username) {
        const userInfo = await sendRequest({
          url: `/resources/users/${state.username}`,
          method: "GET",
        });
        commit("setUserInfo", userInfo);
      }
    },
    async loadUserOrganization({ commit, state }) {
      // get user data if user is login
      if (state.isLogin && state.username) {
        const userOrgs = await sendRequest({
          url: `/relationships/member?source_id=${state.username}`,
          method: "GET",
          isAuth: true,
        });
        commit("setUserOrganizations", userOrgs);
      }
    },
  },
};
export default userModule;
